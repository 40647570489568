'use client';

import useAuth from 'hooks/useAuth';
import { usePathname, useRouter } from 'next/navigation';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { isAuthPageUtil, isHubspotCallbackUtil, isUnsubscribePageUtil } from 'utils/pageUtil';
type TProtectedContext = {
  user: { username: string } & Record<string, unknown>;
};

const ProtectedContext = createContext<TProtectedContext>({
  user: { username: '' }
});

const useProtectedContext = (): TProtectedContext => useContext(ProtectedContext);

const initialUser = { username: '' };

const ProtectedProvider: FC<PropsWithChildren<unknown>> = ({ children }): JSX.Element => {
  const [user, setUser] = useState(initialUser);
  const [checking, setChecking] = useState(true);
  const auth = useAuth();
  const pathname = usePathname();

  const isAuthPage = isAuthPageUtil(pathname);
  const isUnsubscribePage = isUnsubscribePageUtil(pathname);
  const isHubspotCallback = isHubspotCallbackUtil(pathname);

  const isSignInSingUp = ['/auth/sign-in', '/auth/sign-up'].some((route) =>
    pathname.includes(route)
  );

  const isSignOut = pathname.includes('/auth/sign-out');

  const protectedRoutes = [isAuthPage, isUnsubscribePage, isHubspotCallback];

  const isProtected = protectedRoutes.some((route) => route);

  const router = useRouter();

  useEffect(() => {
    if (!isSignOut && process.env.NEXT_PUBLIC_LOCAL_AUTH !== 'true') {
      auth
        .currentAuthenticatedUser()
        .then((userNew: any) => {
          if (isSignInSingUp) router.push('/');
          setTimeout(() => {
            setChecking(false);
            setUser(userNew);
          }, 1000);
        })
        .catch(() => {
          if (!isProtected) router.push('/auth/sign-out');
          setTimeout(() => {
            setUser(initialUser);
            setChecking(false);
          }, 1000);
        });
    } else {
      setChecking(false);
    }
  }, [router, isAuthPage, auth, isProtected, isSignOut, isSignInSingUp]);

  useEffect(() => {
    if (isSignOut) setUser(initialUser);
  }, [isSignOut]);

  // if (
  //   ((!user.username && !isProtected) || checking) &&
  //   process.env.NEXT_PUBLIC_LOCAL_AUTH !== 'true'
  // )
  //   return <Loading />;

  return <ProtectedContext.Provider value={{ user }}>{children}</ProtectedContext.Provider>;
};

export { ProtectedProvider, useProtectedContext };
